import React, {useState} from 'react'
import styled from 'styled-components'
import {HEADER, COLOURS as colours} from 'consts.json'
import CPLink from 'components/CPLink'
import withTranslation from 'common/HOCs/withTranslation'
import type {HeaderTypeAppearance} from '../../../../CPLayout/types'
import Dropdown, {DropdownTrigger} from '../../../../common/CPDropdown'
import CPAppContext from 'common/context/CPApp/CPAppContext'

const StyledDropdown = styled(Dropdown)`
  margin: 0;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
`

const DropdownSegment = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  list-style-type: none;
  margin: 0;
  margin-bottom: 24px;
  background-color: #f9f9f9;
  padding: 16px 64px 16px 34px;
`

const DropdownTriggerItem = styled.div`
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: ${props => (props.isActive ? 'bold' : 'inherit')};
  color: ${props => (props.isActive ? colours.drawerActiveColor : '#253044')};
  opacity: ${props => (props.isActive ? 'none' : '0.56')};
  transition: all 0.3s ease-out;

  :hover {
    color: ${props => (props.isActive ? colours.drawerActiveColor : '#253044')};
  }
`

const ListItem = styled.li`
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
  vertical-align: middle;
  display: table-cell;
  height: inherit;
`

const LinkItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
  transition: all 0.3s ease-in-out;
  font-weight: ${props => (props.isActive ? 'bold' : 'inherit')};
  color: #253044;
  opacity: 0.56;
  &:before {
    position: absolute;
    content: '';
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    border: ${props =>
      props.withBox
        ? props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
          ? '1px solid white'
          : '1px solid gray'
        : 'none'};
    background-color: ${props =>
      props.withBox && props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
        ? 'rgba(255, 255, 255, 0.1)'
        : 'transparent'};
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 60px;
    height: 3px;
    background-color: ${props =>
      props.isActive
        ? props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
          ? 'white'
          : colours.activeLink
        : null};
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
  }

  :hover {
    span {
      color: ${props =>
        props.isActive
          ? props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
            ? 'white'
            : colours.activeLink
          : 'grey'};
    }

    &:before {
      border: ${props => (props.withBox ? '1px solid gray' : 'none')};
      background-color: 'transparent';
    }
  }
`

const CPLinkText = styled.span`
  transition: all 0.3s ease-in-out;
  padding: 10px 0px;
  font-size: 14px;

  @media screen and (min-width: 1124px) {
    padding: 10px 0px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 14px;
    padding: 10px 0px;
  }

  ${props =>
    props.separator &&
    `&:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 15px;
    height: 1px;
    background-color: #898989;
    transform: translateX(-50%) rotate(90deg);
    transition: all 0.3s ease-in-out;
  }`};
`

const LinkWrapper = styled.div``

type Props = {
  key: String,
  item: Object,
  isActive: Boolean,
  headerAppearance?: HeaderTypeAppearance
}

const CPDrawerDropdownItem = (props: Props) => {
  const dropdownRef = React.createRef()

  const [show, setShow] = useState(false)
  const onClickHandler = () => {
    if (show) {
      setShow(false)
    } else {
      setShow(true)
    }
  }

  const dropdownStyle = {
    padding: show ? '0px 24px 0px 34px' : '0px 24px 24px 34px'
  }

  return (
    <ListItem authButton={props.authButton}>
      <StyledDropdown ref={dropdownRef}>
        <DropdownTrigger
          id="tour-lang-dropdown-trigger"
          style={dropdownStyle}
          onClick={onClickHandler}
        >
          <DropdownTriggerItem onClick={onClickHandler}>
            {props.t(show ? props.text.opened : props.text.closed)}
          </DropdownTriggerItem>
        </DropdownTrigger>
        <DropdownSegment show={show}>
          <CPAppContext.AppConsumer>
            {AppContext =>
              props.items.map((item, index) => (
                <LinkWrapper
                  key={index}
                  onClick={() => AppContext.setContextData({showSideDrawer: false})}
                >
                  <CPLink href={item.link} key={item.link + '-' + index}>
                    <LinkItem
                      isActive={false}
                      key={item.link}
                      headerAppearance={props.headerAppearance}
                      withBox={props.withBox}
                    >
                      <CPLinkText
                        authButton={props.authButton}
                        separator={props.separator}
                        id={`toolbar-nav-item-${props.t(item.text)}`}
                      >
                        {props.t(item.text)}
                      </CPLinkText>
                    </LinkItem>
                  </CPLink>
                </LinkWrapper>
              ))
            }
          </CPAppContext.AppConsumer>
        </DropdownSegment>
      </StyledDropdown>
    </ListItem>
  )
}

CPDrawerDropdownItem.defaultProps = {
  headerAppearance: HEADER.APPEARANCES.NORMAL,
  withBox: false,
  authButton: false,
  separator: false
}

export default withTranslation(CPDrawerDropdownItem)
