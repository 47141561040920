import React from 'react'
import styled from 'styled-components'
import {COLOURS as colours} from 'consts.json'
import CPAppContext from 'common/context/CPApp/CPAppContext'
import CPLink from 'components/CPLink'

const ListItem = styled.li`
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
  display: block;
  width: 100%;
  padding: 0 4px 0 0px;
`
const NoHrefItem = styled.div`
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 13px 8px 13px 8px;
  height: 50px;
  cursor: pointer;
  font-weight: ${props => (props.isActive ? 'bold' : 'inherit')};
  color: ${props => (props.isActive ? colours.drawerActiveColor : '#253044')};
  background-color: ${props => (props.isActive ? colours.drawerActiveBackground : 'white')};
  transition: all 0.3s ease-out;

  :hover {
    color: ${props => (props.isActive ? colours.drawerActiveColor : 'grey')};
  }
`

const LinkItem = styled.a`
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0px 24px 24px 24px;
  cursor: pointer;
  font-size: 14px;
  font-weight: ${props => (props.isActive ? 'bold' : 'inherit')};
  color: ${props => (props.isActive ? colours.drawerActiveColor : '#253044')};
  opacity: ${props => (props.isActive ? 'none' : '0.56')};
  transition: all 0.3s ease-out;

  :hover {
    color: ${props => (props.isActive ? colours.drawerActiveColor : 'grey')};
  }
`
const LabelWrapper = styled.span`
  font-size: 14px;
  padding-left: 10px;
  vertical-align: super;
  flex: 1;
`

type Props = {
  id: String,
  link: String,
  icon: String,
  isActive: Boolean,
  children?: React.Node
}

type PropsWithoutLink = {
  icon: String,
  onItemClick: Function,
  children?: React.Node
}

const setSideDrawerVisibility = (AppContext, visibility) => {
  AppContext.setContextData({
    showSideDrawer: visibility
  })
}

const CPDrawerItem = (props: Props) => {
  const callback = props.callback || (() => {})

  const ItemWrapper = () =>
    !props.noHref ? (
      <CPLink href={props.link}>
        <LinkItem isActive={props.isActive} id={`cp_drawer_item-${props.id}`}>
          <LabelWrapper>{props.children}</LabelWrapper>
        </LinkItem>
      </CPLink>
    ) : (
      <NoHrefItem isActive={props.isActive} id={`cp_drawer_item-${props.id}`}>
        <LabelWrapper>{props.children}</LabelWrapper>
      </NoHrefItem>
    )

  return (
    <CPAppContext.AppConsumer>
      {AppContext => {
        return (
          <ListItem
            onClick={() => {
              setSideDrawerVisibility(AppContext, !AppContext.showSideDrawer)
              callback()
            }}
          >
            <ItemWrapper />
          </ListItem>
        )
      }}
    </CPAppContext.AppConsumer>
  )
}

export const CPDrawerItemWithoutLink = (props: PropsWithoutLink) => {
  return (
    <CPAppContext.AppConsumer>
      {AppContext => {
        return (
          <ListItem
            onClick={() => {
              setSideDrawerVisibility(AppContext, !AppContext.showSideDrawer)
              props.onItemClick()
            }}
          >
            <LinkItem id={`cp_drawer_item-${props.id}`}>
              <LabelWrapper>{props.children}</LabelWrapper>
            </LinkItem>
          </ListItem>
        )
      }}
    </CPAppContext.AppConsumer>
  )
}

export default CPDrawerItem
