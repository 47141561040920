import React from 'react'

import {withRouter} from 'next/router'
import {BECOME_A_HOST, DRAWER_MENU_SIDE_MENU, HOST_HOME, LOG_IN, SIGN_UP} from 'routes.json'
import styled from 'styled-components'

import withAppContext from 'common/HOCs/withAppContext'
import withTranslation from 'common/HOCs/withTranslation'
import withUserContext from 'common/HOCs/withUserContext'
import CPAppContext from 'common/context/CPApp/CPAppContext'
import {isRouteActive} from 'common/helpers'
import CPLink from 'components/CPLink'
import {HEADER, HEADER_TYPES} from 'consts.json'

import CPSelectLanguage from '../../../common/CPSelectLanguage'

import CPDrawerItem from './CPDrawerItem'
import CPDrawerDropdownItem from './CPDrawerItem/dropdown'

const Wrapper = styled.ul`
  font-family: 'Nunito Sans', Helvetica;
  margin: 0 auto;
  padding: 0 !important;
  height: calc(
    100% -
      ${props =>
        props.showHeaderBar && !props.showDownloadAppBanner
          ? '105px'
          : props.showHeaderBar && props.showDownloadAppBanner
          ? '170px'
          : !props.showHeaderBar && props.showDownloadAppBanner
          ? '130px'
          : '68px'}
  );
  display: flex;
  flex-direction: column;
`

const DrawerItemsWrapper = styled.div`
  margin-bottom: 5px;
  flex: 1;
`

const customStyle = {
  background: '#F9F9F9',
  width: '100% !important',
  margin: '8px 24px 6px 24px',
  'border-radius': '8px',
  'font-size': '18px',
  'margin-bottom': '0 !important'
}

const AuthButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 24px 40px 24px;
`

const AuthButtonWrapper = styled.div`
  flex: 0 0 50%;
  text-align: center;
`

const LoginButton = styled.a`
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
  color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(51, 75, 99, 1);
  background-color: rgba(51, 75, 99, 1);
  box-sizing: border-box;
  border-radius: 3px;
  display: inline-block;
  text-decoration: none;
  width: 90%;
  height: 40px;
  line-height: 40px;
  transition: all 0.3s ease-out;
  border-radius: 8px;

  &:hover,
  &:active {
    text-decoration: none;
    border: 1px solid rgba(51, 75, 99, 0.5);
    background-color: rgba(51, 75, 99, 1);
    color: #ffffff;
  }
`

const SignInButton = styled.a`
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
  color: rgba(51, 75, 99, 1);
  border: 1px solid rgba(51, 75, 99, 1);
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
  display: inline-block;
  text-decoration: none;
  width: 90%;
  height: 40px;
  line-height: 40px;
  transition: all 0.3s ease-out;
  border-radius: 8px;

  &:hover,
  &:active {
    text-decoration: none;
    border: 1px solid rgba(51, 75, 99, 0.5);
    background-color: rgba(51, 75, 99, 1);
    color: #ffffff;
  }
`

const WhiteSpaceDiv = styled.div`
  padding-top: 24px;
`

const LanguageSelectWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 24px;
`

type Props = {
  router: Object, // next.js router object
  showLangDropdown: boolean,
  appearance: string
}

export const CPDrawerItems = (props: Props) => {
  const {isLogged, isHost} = props.user
  const hostLinkMapping = isHost ? HOST_HOME : BECOME_A_HOST
  const drawerItems = []

  const closeDrawer = context => {
    context.setContextData({
      showSearch: false,
      showSideDrawer: false
    })
  }

  for (const [key, route] of Object.entries(DRAWER_MENU_SIDE_MENU)) {
    drawerItems.push(
      route.type === HEADER_TYPES.DROPDOWN ? (
        <CPDrawerDropdownItem
          id={key}
          key={key}
          items={route.items}
          text={route.text}
          isActive={route.items.find(item => isRouteActive(props.router, props.i18n, item.link))}
          headerAppearance={props.headerAppearance}
        />
      ) : (
        <CPDrawerItem
          id={key}
          key={key}
          link={route.link}
          isActive={isRouteActive(props.router, props.i18n, route.link)}
          type={route.type}
        >
          {props.t(route.text)}
        </CPDrawerItem>
      )
    )
  }

  drawerItems.push(
    <CPDrawerItem
      key={'host_link'}
      link={hostLinkMapping.link}
      isActive={isRouteActive(props.router, props.i18n, hostLinkMapping.link)}
    >
      {props.t(hostLinkMapping.text)}
    </CPDrawerItem>
  )

  const drawAuthButtons = () => {
    if (!isLogged) {
      return (
        <CPAppContext.AppConsumer>
          {appContext => (
            <AuthButtonsWrapper>
              <AuthButtonWrapper onClick={() => closeDrawer(appContext)}>
                <CPLink href={SIGN_UP.link}>
                  <SignInButton>{props.t('SIGN_UP')}</SignInButton>
                </CPLink>
              </AuthButtonWrapper>
              <AuthButtonWrapper onClick={() => closeDrawer(appContext)}>
                <CPLink href={LOG_IN.link}>
                  <LoginButton>{props.t('LOGIN_BUTTON_TEXT')}</LoginButton>
                </CPLink>
              </AuthButtonWrapper>
            </AuthButtonsWrapper>
          )}
        </CPAppContext.AppConsumer>
      )
    }
    return <WhiteSpaceDiv />
  }

  return (
    <Wrapper
      showHeaderBar={props.showHeaderBar}
      showDownloadAppBanner={props.showDownloadAppBanner}
    >
      <DrawerItemsWrapper>
        {drawAuthButtons()}
        {drawerItems}
      </DrawerItemsWrapper>
      {props.showLangDropdown && (
        <LanguageSelectWrapper>
          <CPSelectLanguage isSideDrawer={true} customStyle={customStyle} />
        </LanguageSelectWrapper>
      )}
    </Wrapper>
  )
}

CPDrawerItems.defaultProps = {
  showLangDropdown: true,
  user: {},
  appearance: HEADER.APPEARANCES.NORMAL
}

export default withAppContext(withUserContext(withRouter(withTranslation(CPDrawerItems))))
