import React, {Component} from 'react'

import {NextSeo} from 'next-seo'
import {withRouter} from 'next/router'
import styled from 'styled-components'

import CPAppContext from 'common/context/CPApp/CPAppContext'
import {resolveMetaConfig} from 'common/helpers'
import CPNavigationFooter from 'components/CPNavigation/CPNavigationFooter'
import {DEFAULT_SEO, HEADER, NAV_FOOTER} from 'consts.json'

import CPSideDrawer from '../CPNavigation/CPSideDrawer'
import CPToolbar from '../CPNavigation/CPToolbar'

import CPFooter from './common/CPFooter'

import type {HeaderType} from './types'

const calculateContentTopOffset = (showHeader, showHeaderBar, showAppDownloadBanner) => {
  if (!showHeader) {
    return 0
  }
  let topOffset = HEADER.DEFAULT_HEIGHT
  if (showHeaderBar) {
    topOffset += HEADER.BAR_HEIGHT
  }
  if (showAppDownloadBanner) {
    topOffset += HEADER.DOWNLOAD_APP_BANNER_HEIGHT
  }
  return `${topOffset}px`
}

const Wrapper = styled.div`
  padding-bottom: ${NAV_FOOTER.DEFAULT_HEIGHT}px;

  ${props => props.theme.mediaQuery.lg} {
    padding-bottom: 0;
  }
`

const ContentContainer = styled.div`
  min-height: 70vh;
  background-color: white;
  padding-top: ${props => props.topOffset};

  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
`
type Props = {
  seoConfig: Object,
  isContentStickedToTop?: boolean,
  header?: HeaderType,
  showLangDropdown: boolean,
  children?: React.Node,
  showHeader?: boolean,
  showHeaderOnMobileOnly?: boolean,
  showFooter?: boolean,
  allowSideBarOnWideScreen?: boolean,
  footerStyle?: Object
}

const setSideDrawerVisibility = (AppContext, sideDrawerVisiblity) => {
  AppContext.setContextData({
    showSideDrawer: sideDrawerVisiblity
  })
}

export class CPLayout extends Component<Props> {
  render() {
    const showHeaderBar = false
    let seoConfig = DEFAULT_SEO

    if (this.props.seoConfig) {
      const {title, description, images, canonical} = this.props.seoConfig
      seoConfig = resolveMetaConfig(title, description, images, canonical)
    }
    return (
      <CPAppContext.AppConsumer>
        {AppContext => {
          const isHeaderHidden =
            this.props.header && this.props.header.appearance === HEADER.APPEARANCES.HIDDEN
          const topOffset = this.props.isContentStickedToTop
            ? 0
            : calculateContentTopOffset(
                this.props.showHeader && !isHeaderHidden,
                showHeaderBar,
                AppContext.showDownloadAppBanner
              )
          return (
            <Wrapper className={this.props.className}>
              <NextSeo {...seoConfig} />
              {this.props.showHeader && (
                <CPToolbar
                  showLangDropdown={this.props.showLangDropdown}
                  shouldSwitchOpen={this.props.shouldSwitchOpen}
                  {...this.props.header}
                  onDrawerToggleClicked={() => setSideDrawerVisibility(AppContext, true)}
                  deepLinkConfig={this.props.deepLinkConfig}
                  showHeaderOnMobileOnly={this.props.showHeaderOnMobileOnly}
                  showHeaderBar={showHeaderBar}
                />
              )}
              {this.props.showHeader && (
                <CPSideDrawer
                  showLangDropdown={this.props.showLangDropdown}
                  {...this.props.header}
                  allowSideBarOnWideScreen={this.props.allowSideBarOnWideScreen}
                  isOpened={AppContext.showSideDrawer}
                  onBackdropClicked={() => setSideDrawerVisibility(AppContext, false)}
                  onDrawerToggleClicked={() =>
                    setSideDrawerVisibility(AppContext, !AppContext.showSideDrawer)
                  }
                  showHeaderBar={showHeaderBar}
                  showDownloadAppBanner={AppContext.showDownloadAppBanner}
                />
              )}
              <ContentContainer topOffset={topOffset}>{this.props.children}</ContentContainer>
              {this.props.showFooter && (
                <CPFooter
                  customWrapperStyle={this.props.customFooterWrapperStyle}
                  customFooterStyle={this.props.footerStyle}
                />
              )}
              <CPNavigationFooter />
            </Wrapper>
          )
        }}
      </CPAppContext.AppConsumer>
    )
  }
}

export const FixedNavCPLayout = styled(CPLayout)`
  .footer-navigation-wrapper {
    position: fixed;
  }
`

CPLayout.defaultProps = {
  isContentStickedToTop: false,
  showLangDropdown: true,
  showHeader: true,
  showHeaderOnMobileOnly: false,
  shouldSwitchOpen: false
}
export default withRouter(CPLayout)
