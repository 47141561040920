import React from 'react'
import styled from 'styled-components'
import {changeLanguageHelper} from 'common/helpers'
import {languageMapHeader} from '../../CPLayout/common/CPFooter/footerMappings'
import Select, {components} from 'react-select'
import {withRouter} from 'next/router'
import {HEADER, SELECT_LANGUAGE_THEME} from 'consts.json'
import {CPNavigationItems} from '../../CPNavigation/CPNavigationItems'
import withTranslation from 'common/HOCs/withTranslation'

const SelectLang = styled(Select)`
  border: none;
  cursor: pointer;
  ${props => (props.fontSize ? props.fontSize : 'font-size: 14px')};
  padding: ${props =>
    `${
      props.location === HEADER.TOOLBAR
        ? '0'
        : props.isSideDrawer
        ? '10px 20px 10px 20px'
        : '10px 0 2px 20px'
    }`};
  width: ${props =>
    props.currentLang === 'id' ? '70%' : props.location === HEADER.TOOLBAR ? '45%' : '55%'};
  span {
    display: none;
  }
  div:first-child {
    border: none;
    cursor: pointer;
    span {
      display: none;
    }
  }
`
const SelectWrapper = styled.div`
  display: flex;
  width: ${props =>
    props.location === HEADER.TOOLBAR ? '250px' : props.isSideDrawer ? '100%' : '285px'};
  font-family: 'Nunito Sans', Helvetica;
  justify-content: ${props => (props.location === HEADER.TOOLBAR ? 'center' : 'initial')};

  @media screen and (min-width: 900px) {
    margin-right: ${props => (props.location === HEADER.TOOLBAR ? '50px' : '0')};
  }
`

const SelectDropIcon = styled.img`
  height: 10px;
  width: 10px;
  cursor: pointer;
`

const SelectLangIcon = styled.div`
  width: ${props => (props.location === HEADER.TOOLBAR ? '20px' : '23px')};
  margin: ${props => (props.location === HEADER.TOOLBAR ? '9px 0 0 17px' : '22px 0 0 20px')};
  background: ${props => `url(${props.icon})`};
  background-size: contain;
  background-repeat: no-repeat;
`

//Custom Select Style (https://react-select.com/styles)
const customStyle = (theme, location, isSideDrawer) => {
  return {
    control: base => ({
      ...base, // eslint-disable-next-line
      border: '0', // eslint-disable-next-line
      boxShadow: '0',
      '&:hover': {border: '0'}, // eslint-disable-next-line
      zIndex: 999, // eslint-disable-next-line
      backgroundColor: 'none'
    }),
    option: (provided, state) => ({
      ...provided, // eslint-disable-next-line
      color: state.isSelected ? '#e14d6d !important' : '', // eslint-disable-next-line
      backgroundColor: state.isSelected ? '#FCEDF0' : '', // eslint-disable-next-line
      fontWeight: state.isSelected ? 'bold' : '', // eslint-disable-next-line
      padding: 10, // eslint-disable-next-line
      cursor: 'pointer',
      '&:hover': {
        color: theme === SELECT_LANGUAGE_THEME.LIGHT ? 'white' : 'grey',
        backgroundColor: 'rgb(252, 250, 250, 0.5)'
      },
      ':active': {backgroundColor: 'rgb(252, 250, 250, 0.5)'}
    }),
    menu: provided => ({
      ...provided,
      margin:
        location === HEADER.TOOLBAR
          ? '-353px 0  0 -25px'
          : isSideDrawer
          ? '-360px 0  0 -10px'
          : '-360px 0  0 -40px ',
      backgroundColor:
        theme === SELECT_LANGUAGE_THEME.LIGHT
          ? HEADER.APPEARANCES.TRANSPARENT
          : SELECT_LANGUAGE_THEME.LIGHT.COLOR,
      width: 215,
      border: '1px solid lightgrey',
      borderRadius: 5,
      boxShadow: '0px 0px 10px -8px rgba(0,0,0,0.75)',
      color: theme.COLOR
    }),
    dropdownIndicator: () => ({marginRight: 20}),
    singleValue: () => ({color: theme.COLOR})
  }
}

type Props = {
  headerAppearance?: String,
  location?: String,
  router: Object,
  i18n: Object,
  fontSize?: String,
  customStyle?: Object,
  isSideDrawer: Boolean
}

export const CPSelectLanguage = (props: Props) => {
  const {language: currentLang} = props.i18n
  const theme =
    props.headerAppearance && props.headerAppearance === HEADER.APPEARANCES.TRANSPARENT
      ? SELECT_LANGUAGE_THEME.LIGHT
      : SELECT_LANGUAGE_THEME.DARK

  const getCurrentLanguageLabel = () => {
    return languageMapHeader.filter(lang => {
      return lang.value === currentLang
    })
  }

  //Change default select icon (https://react-select.com/styles)
  const dropIcon = `/static/images/dropdown_filled_${theme.ICON}.svg`
  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <SelectDropIcon src={dropIcon} />
        </components.DropdownIndicator>
      )
    )
  }

  return (
    <SelectWrapper
      isSideDrawer={props.isSideDrawer}
      data-cy={'select-wrapper'}
      location={props.location}
    >
      {props.isSideDrawer ? (
        ''
      ) : (
        <SelectLangIcon
          icon={`/static/images/languages/${currentLang}.png`}
          location={props.location}
        />
      )}

      <SelectLang
        instanceId={currentLang}
        isSearchable={false}
        components={{DropdownIndicator}}
        value={getCurrentLanguageLabel()}
        onChange={e => changeLanguageHelper(e.value)}
        options={languageMapHeader}
        styles={customStyle(theme, props.location, currentLang, props.isSideDrawer)}
        location={props.location}
        currentLang={currentLang}
        theme={theme}
        classNamePrefix="select-language"
        fontSize={props.customStyle}
        isSideDrawer={props.isSideDrawer}
      />
    </SelectWrapper>
  )
}

CPNavigationItems.defaultProps = {
  headerAppearance: HEADER.APPEARANCES.NORMAL
}

export default withTranslation(withRouter(CPSelectLanguage))
