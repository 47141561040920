import React from 'react'

import styled from 'styled-components'

import {HEADER} from 'consts.json'

import Backdrop from '../../CPBackdrop'

import CPDrawerItems from './CPDrawerItems'

const SideDrawer = styled.div`
  position: fixed;
  width: 300px;
  height: 100%;
  right: 0;
  top: ${props =>
    props.showHeaderBar && !props.showDownloadAppBanner
      ? '105px'
      : props.showHeaderBar && props.showDownloadAppBanner
      ? '170px'
      : !props.showHeaderBar && props.showDownloadAppBanner
      ? '130px'
      : '68px'};
  z-index: 1200;
  background-color: white;
  box-sizing: border-box;
  transition: transform 0.4s ease-out;
  transform: ${props => (props.isOpened ? 'translateX(0)' : 'translateX(101%)')};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  overflow: auto;

  @media only screen and (min-height: 1124px) {
    overflow: hidden;
  }

  @media only screen and (min-width: 1124px) {
    display: ${props => (props.allowSideBar ? 'block' : 'none')};
  }

  @media screen and (max-width: 1024px) and (max-height: 768px) and (orientation: landscape) {
    display: block;
  }
`
const DrawerToggleWrapper = styled.div`
  border-top: 1px solid #253044 80%;
  height: 100%;
`

type Props = {
  isOpened: Boolean,
  showLangDropdown: boolean,
  onBackdropClicked?: Function,
  onDrawerToggleClicked?: Function,
  allowSideBarOnWideScreen?: Boolean,
  showHeaderBar: Boolean,
  showDownloadAppBanner: Boolean
}

const CPSideDrawer = (props: Props) => {
  return (
    <>
      <Backdrop
        show={props.isOpened}
        isMobile={true}
        showHeaderBar={props.showHeaderBar}
        onClick={props.onBackdropClicked}
        showDownloadAppBanner={props.showDownloadAppBanner}
      />
      <SideDrawer
        showHeaderBar={props.showHeaderBar}
        showDownloadAppBanner={props.showDownloadAppBanner}
        isOpened={props.isOpened}
        id="side-drawer"
        allowSideBar={props.allowSideBarOnWideScreen}
      >
        <DrawerToggleWrapper>
          <CPDrawerItems
            showLangDropdown={props.showLangDropdown}
            appearance={props.appearance}
            showHeaderBar={props.showHeaderBar}
            showDownloadAppBanner={props.showDownloadAppBanner}
          />
        </DrawerToggleWrapper>
      </SideDrawer>
    </>
  )
}

CPSideDrawer.defaultProps = {
  isOpened: false,
  showDownloadAppBanner: false,
  showLangDropdown: true,
  onDrawerToggleClicked: () => {},
  onBackdropClicked: () => {},
  appearance: HEADER.APPEARANCES.NORMAL,
  allowSideBarOnWideScreen: false
}

export default CPSideDrawer
