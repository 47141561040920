import React from 'react'

import styled from 'styled-components'

import icons from 'assets/icons'
import withTranslation from 'common/HOCs/withTranslation'
import {resolveLinkUrl} from 'common/helpers/url'
import CPLink from 'components/CPLink'

import {LINK_TYPE, footerLinksMap, socialLinks} from './footerMappings'

const FooterContainer = styled.div`
  overflow-x: hidden;
  position: relative;
  flex-wrap: wrap;
  display: ${props =>
    props.customFooterStyle &&
    props.customFooterStyle.mediaQuery &&
    props.customFooterStyle.mediaQuery.visibilityOn
      ? 'none'
      : 'flex'};
  justify-content: center;
  align-items: center;

  margin-top: ${props => (props.customFooterStyle ? props.customFooterStyle.marginTop : '35px')};
  padding-top: 35px;
  border-top: 1px solid rgba(215, 215, 215, 1);
  background-color: rgba(249, 249, 249, 1);

  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  @media only screen and (min-width: 768px) {
    margin-top: ${props => (props.customFooterStyle ? props.customFooterStyle.marginTop : '35px')};
  }

  @media only screen and (min-width: 1000px) {
    display: flex;
  }
`

const FooterWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0;
  margin: auto;
  width: calc(100vw - 48px);
  max-width: 1152px;

  @media only screen and (min-width: 320px) {
    justify-content: center;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const FooterItemContainer = styled.div`
  width: 148px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  overflow: hidden;

  @media only screen and (min-width: 800px) {
    margin-bottom: 49px;
  }
`

const SocialFooterItemContainer = styled(FooterItemContainer)`
  text-decoration: none;
  color: #4a4a4a;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;

  @media only screen and (max-width: 505px) {
    height: auto;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 979px) and (min-width: 663px) {
    width: 15%;
    align-items: flex-start;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1340px) {
    width: 15%;
    align-items: flex-start;
    margin-bottom: 0;
  }
`

const StoresFooterItemContainer = styled(FooterItemContainer)`
  width: 290px;

  @media only screen and (max-width: 505px) {
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 979px) and (min-width: 663px) {
    width: 40%;
    height: 100px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1972px) {
    width: 15%;
    height: 91px;
    flex-direction: column;
    margin-bottom: 0;
    justify-content: flex-end;
    align-items: flex-start;
  }
`

const ItemTitleIcon = styled.div`
  width: 20px;
  margin-left: 5px;

  @media only screen and (min-width: 800px) {
    display: none;
  }
`

const ItemTitle = styled.div`
  color: rgba(44, 57, 81, 0.48);
  font-size: 10px;
  font-family: 'Nunito Sans', sans-serif;
  margin-bottom: 20px;
  display: flex;

  .active {
    color: #faa929;

    & > svg {
      transform: rotate(180deg);
    }
  }
`

const ItemTitleText = styled.span``

const ItemTitleComponent = styled(ItemTitle)`
  display: none;

  @media only screen and (min-width: 800px) {
    display: inline;
  }
`

const FooterLink = styled.a`
  text-decoration: none;
  color: #4a4a4a;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
  margin-bottom: 15px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`

const CreditsContainer = styled.div`
  width: 100%;
  padding: 32px 0%;
  border-top: 1px solid rgba(215, 215, 215, 0.4);

  @media only screen and (max-width: 505px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }
`

const Credits = styled.span`
  color: rgba(37, 48, 68, 0.3);
  font-size: 12px;
  font-family: 'Nunito Sans', sans-serif;
  float: right;

  @media (max-width: 505px) {
    font-size: 10px;
  }

  @media (min-width: 506px) {
    margin-top: 10px;
  }
`

const LinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 0;
  transition: max-height 0.2s ease-out;

  @media (min-width: 960px) {
    max-height: unset;
  }
`
const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }

  @media only screen and (min-width: 980px) {
    justify-content: flex-start;
  }
`

const LogoLink = styled.a`
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  text-transform: capitalize;

  :hover {
    opacity: 0.5;
  }
`

const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    height: 100%;
    padding-right: 0.5em;
  }
`

const SvgTitle = styled.p`
  display: none;
  margin: 0;

  @media only screen and (min-width: 800px) {
    display: inline-block;
  }
`

const CreditLogo = styled.div`
  height: 32px;
  display: inline-block;
  & > svg {
    height: 100%;
  }

  @media (max-width: 505px) {
    height: 22px;
  }
`

const StoreWrapper = styled.div`
  display: flex;

  @media only screen and (min-width: 768px) and (max-width: 979px) {
    flex-direction: column;
    margin-bottom: 30px;
  }
`

const renderIcons = type => {
  const logos = icons.footer[type]
  return Object.keys(logos).map((el, index) => {
    const Logo = logos[el]
    return (
      <LogoContainer key={index}>
        <LogoLink target="_blank" href={socialLinks[el]}>
          <SvgWrapper>
            <Logo />
            <SvgTitle>{type === 'social' ? el : ''}</SvgTitle>
          </SvgWrapper>
        </LogoLink>
      </LogoContainer>
    )
  })
}

const termsAndConditions = 'TERMS_AND_CONDITIONS'
const privacyPolicy = 'PRIVACY_POLICY'

const renderLinkBoxes = (translate, currLang) => {
  return Object.keys(footerLinksMap).map((el, index) => (
    <FooterItemContainer key={index}>
      <ItemTitle
        onClick={() => {
          const titleEl = document.getElementById(`link-title-${index}`)
          titleEl.classList.toggle('active')
          const iconEl = document.getElementById(`link-icon-${index}`)
          iconEl.classList.toggle('active')
          const containerEl = document.getElementById(`links-${index}`)
          if (containerEl.style.maxHeight) {
            containerEl.style.maxHeight = null
          } else {
            containerEl.style.maxHeight = containerEl.scrollHeight + 'px'
          }
        }}
      >
        <ItemTitleText id={`link-title-${index}`}>{translate(el).toUpperCase()}</ItemTitleText>
        <ItemTitleIcon id={`link-icon-${index}`}>
          <icons.chevron.down />
        </ItemTitleIcon>
      </ItemTitle>
      <LinksContainer id={`links-${index}`}>
        {footerLinksMap[el].map((link, linkIndex) => (
          <CPLink
            href={
              link.title === termsAndConditions || link.title === privacyPolicy
                ? resolveLinkUrl(link.title, currLang)
                : link.url
            }
            key={linkIndex}
          >
            <FooterLink target={link.target || '_self'}>{translate(link.title)}</FooterLink>
          </CPLink>
        ))}
      </LinksContainer>
    </FooterItemContainer>
  ))
}

const Copyright = () => {
  const CompanyLogo = icons.logo['normal']
  return (
    <CreditsContainer>
      <CreditLogo>
        <CompanyLogo />
      </CreditLogo>
      <Credits>© {new Date().getFullYear()} OROUNDO Mobile GmbH</Credits>
    </CreditsContainer>
  )
}

const CPFooter = props => {
  return (
    <FooterContainer
      className="cp-page-footer"
      {...props.customWrapperStyle}
      customFooterStyle={props.customFooterStyle}
    >
      <FooterWrapper customFooterStyle={props.customFooterStyle}>
        {renderLinkBoxes(props.t, props.i18n.language)}
        <SocialFooterItemContainer>
          <ItemTitleComponent>{props.t('STAY_IN_TOUCH').toUpperCase()}</ItemTitleComponent>
          {renderIcons(LINK_TYPE.SOCIAL)}
        </SocialFooterItemContainer>
        <StoresFooterItemContainer>
          <ItemTitleComponent>{props.t('DOWNLOAD_APPS').toUpperCase()}</ItemTitleComponent>
          <StoreWrapper>{renderIcons(LINK_TYPE.STORE)}</StoreWrapper>
        </StoresFooterItemContainer>
        <Copyright />
      </FooterWrapper>
    </FooterContainer>
  )
}

export default withTranslation(CPFooter)
