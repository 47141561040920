import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1100;
  left: 0;
  top: ${props =>
    props.isMobile && props.showHeaderBar && !props.showDownloadAppBanner
      ? '105px'
      : props.isMobile && !props.showHeaderBar && props.showDownloadAppBanner
      ? '130px'
      : props.isMobile && props.showHeaderBar && props.showDownloadAppBanner
      ? '170px'
      : props.isMobile && !props.showHeaderBar && !props.showDownloadAppBanner
      ? '68px'
      : 0};
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.show ? 'block' : 'none')};
  transition: transform 0.4s ease-out;
`

type Props = {
  show: Boolean,
  isMobile: Boolean,
  showHeaderBar: Boolean,
  onClick: Function,
  showDownloadAppBanner: Boolean
}

const backdrop = (props: Props) => (
  <Wrapper
    showHeaderBar={props.showHeaderBar}
    showDownloadAppBanner={props.showDownloadAppBanner}
    isMobile={props.isMobile}
    show={props.show}
    onClick={props.onClick}
    id="backdrop"
  />
)

export default backdrop
